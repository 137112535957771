import { OrUndefineable } from '@src/types/types.utils';
import moment, { Moment } from 'moment';
import { assertValue } from '../utils/assert';
import { ApiResponse, NameValueOption, ReturnCode, TableDataSource } from './common.model';

/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
export const isSucceededResponse = <T>(response: ApiResponse<T>): boolean => response.returnCode === ReturnCode.SUCCESS;

// ====================================================================================================
// get response error text.
// NOTEs:  we need this function because there are no standard way from the ddo service that can help
// us to get the friend error message but I found the following way is the most reliable way that I
// found to deal with the service, especially with ddo service.
export const getResponseErrorMessage = <T>(response: ApiResponse<T>): OrUndefineable<string> => {
	if (!isSucceededResponse(response)) {
		const splitters = assertValue(response.returnDescription).split('Error :');
		if (splitters.length >= 2) {
			return splitters.at(splitters.length - 1);
		}
	}

	return undefined;
};

export const buildFilterOptions = (data: TableDataSource[], filter: string): NameValueOption[] => {
	const set = new Set<string>();
	data.filter((r) => r[filter]).forEach((r) => set.add(r[filter].toString()));
	return [...set].map((value) => ({
		name: filter,
		value,
	}));
};

export const addPropertiesToEachElement = <T>(arr: T[], obj: Partial<T>): T[] => {
	return arr.map((element) => {
		return { ...element, ...obj };
	});
};

export const addPropertiesToEachObject = <T>(obj: { [k: string]: T }, props: Partial<T>): { [k: string]: T } => {
	const newObj: { [k: string]: T } = {};
	// eslint-disable-next-line no-restricted-syntax, guard-for-in
	for (const objKey in obj) {
		newObj[objKey] = { ...obj[objKey], ...props };
	}
	return newObj;
};

export const formatDataNumberSize = (value: number, bitsToBytes = false, sizes: string[] = [], k = 1024): string => {
	if (!+value) return '0';
	const bytes = bitsToBytes ? value / 8 : value;

	const decimals = 1;
	const newSizes = sizes.length > 0 ? sizes : ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${newSizes[i] || ''}`;
};

export const tickYCallback = (tick: number | string, _index: number, _array: any[], selectedUnit: string): string => {
	const negative: string = +tick < 0 ? '-' : '';
	const value: number = Math.abs(+tick);
	const sizes: string[] = selectedUnit === 'pps' ? ['', 'K', 'M', 'B', 'T', 'P'] : [];
	const k = 1000;
	return negative + formatDataNumberSize(value, false, sizes, k) + selectedUnit;
};

export const formatUnits = (traffic: number, unit: string): string => {
	return tickYCallback(traffic, null, null, unit);
};

export const dateFormat = (date: string | Moment): string => {
	const isSameYear: boolean = moment.utc().isSame(moment.utc(date), 'year');
	return isSameYear ? 'MMM DD HH:mm' : 'MMM DD YYYY HH:mm';
};

export const dateFormatRange = (startDate: string | Moment, endDate: string | Moment): string => {
	const startEndSameDay: boolean = moment.utc(startDate).isSame(moment.utc(endDate), 'day');
	if (startEndSameDay) {
		return 'HH:mm';
	}
	const isSameYear: boolean = moment.utc(endDate).isSame(moment.utc(startDate), 'year');
	return isSameYear ? 'MMM DD HH:mm' : 'MMM DD YYYY HH:mm';
};

export const generateNewColorVariant = (): string => {
	return `#${Math.floor(Math.random() * 16777215)
		.toString(16)
		.padStart(6, '0')}`;
};

export const getColorVariant = (index: number): string => {
	const backgroundColor = ['#003E86', '#649543', '#FF4349', '#FFB74D', '#FF8500'];

	if (index < backgroundColor.length) {
		return backgroundColor[index];
	}

	return generateNewColorVariant();
};
