/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */
import moment from 'moment';
import 'moment-timezone';
import { assertValue } from '@src/app/utils/assert';
import { toKeyObject } from '@src/app/utils/key-object';
import { AppLayoutRouting } from '@src/app/routing-constants';
import { ApiResponse, TableColumn } from '../../common.model';
import {
	DropAndPass,
	NetworkConnectionKeyObject,
	MitigationDataSource,
	MitigationSummary,
	TrafficClass,
	MitigationRate,
	Mitigation,
	MitigationStatus,
	NetworkAddressDetail,
	UpdateNetworkConnections,
	UpdateIpStatus,
	NetworkConnectionCollection,
	MitigationCollection,
	NetWorkConnectionDataSource,
	MitigationDisplayData,
	MitigationKeyObject,
	TrafficUtilSummFlat,
	CMOKeyObject,
} from './mitigation.model';
import { dateFormat } from '../../common.utils';

// calculate mitigation status
// ====================================================================================================
export const getMitigationDisplayData = (mitigationStatus: MitigationStatus): MitigationDisplayData => {
	const status = assertValue(mitigationStatus, 'mitigation status must be defined');

	if (!status.mitigationReady) {
		return {
			mitigation: 'Validating IP',
			disableMitigation: true,
			dataKeyIcon: 'block',
			dataKeyIconColor: 'ddos-error-500',
			tooltip:
				'Mitigation can only start once the IP has been validated.  Contact (844) 288-3367 for immediate assistance',
		};
	}

	if (status.mitigationPending && !status.action) {
		return {
			mitigation: 'Mitigation Start Pending',
			disableMitigation: true,
			dataKeyIcon: 'play_circle',
			dataKeyIconColor: 'ddos-success-500',
			tooltip: 'Mitigation start in progress',
		};
	}

	if (!status.mitigationPending && !status.action) {
		return {
			mitigation: 'Start Mitigation',
			disableMitigation: false,
			dataKeyIcon: 'play_circle',
			dataKeyIconColor: 'ddos-success-500',
			tooltip: 'Start Mitigation',
		};
	}

	// If original requesting from Start Mitigation, means the user wanted to start
	// mitigation.
	if (status.mitigationPending && status.action === 'Start Mitigation') {
		return {
			mitigation: 'Mitigation Start Pending',
			disableMitigation: true,
			dataKeyIcon: 'play_circle',
			dataKeyIconColor: 'ddos-success-500',
			tooltip: 'Mitigation start in progress',
		};
	}

	// If original requesting from Stop Mitigation, means the user wanted to stop
	// mitigation.
	if (status.mitigationPending && status.action === 'Stop Mitigation') {
		return {
			mitigation: 'Mitigation Stop Pending',
			disableMitigation: true,
			dataKeyIcon: 'stop_circle',
			dataKeyIconColor: 'ddos-error-500',
			tooltip: 'Mitigation stop in progress',
		};
	}

	if (!status.mitigationPending && status.action === 'Stop Mitigation') {
		return {
			mitigation: 'Stop Mitigation',
			disableMitigation: false,
			dataKeyIcon: 'stop_circle',
			dataKeyIconColor: 'ddos-error-500',
			tooltip: 'Stop Mitigation',
		};
	}

	return {
		mitigation: 'Start Mitigation',
		disableMitigation: false,
		dataKeyIcon: 'play_circle',
		dataKeyIconColor: 'ddos-success-500',
		tooltip: 'Start Mitigation',
	};
};

// ====================================================================================================

export const getNetworkConnectionDataSource = (
	networkConnectionKeyObject: NetworkConnectionKeyObject[],
	defaultZoneName: string
): NetWorkConnectionDataSource[] => {
	const allKeys = [];
	return networkConnectionKeyObject.flatMap((zone) => {
		const networkConnectionData = zone.keys
			.filter((zoneKey) => !allKeys.includes(zoneKey))
			.map((key) => {
				const network = zone.objects[key];
				allKeys.push(key);
				return {
					defaultZoneName: zone.defaultZoneName,
					netConnectionID: zone.netConnectionID,
					networkAddress: network.networkAddress,
					site: defaultZoneName,
					region: zone.siteNameSSPP,
					mitigationReady: network.mitigationReady,
					mitigationPending: network.mitigationPending,
					...getMitigationDisplayData(network),
				};
			});
		return networkConnectionData;
	});
};

export const isValidDate = (date: string): boolean => {
	return moment(date).isValid();
};

export const getMitigationStatusForNetworkAddress = (mitigation: Mitigation): UpdateIpStatus => {
	return {
		networkAddress: mitigation.networkAddress,
		action: !isValidDate(mitigation.attackEndDate) ? 'Stop Mitigation' : 'Start Mitigation',
		mitigationPending: false,
		mitigationReady: true,
	};
};

export const createDisplayedColumnsStrings = (pzTableColumn: TableColumn[]): string[] => {
	return pzTableColumn.slice().map((col: { dataKey: string }) => col.dataKey);
};

export const groupSamplesByTypeAndUnit = (ratesTotal: TrafficClass[]): DropAndPass => {
	const typeValues: DropAndPass = {
		drop: {},
		pass: {},
	};

	ratesTotal.forEach((rate) => {
		if (rate.type === 'drop' || rate.type === 'pass') {
			const key = `${rate.type}-${rate.unit}`;
			if (!typeValues[rate.type]?.[`${key}`]) {
				typeValues[rate.type][key] = [];
			}
			typeValues[rate.type][`${key}`] = rate.samples;
		}
	});

	return typeValues;
};

export const sortNetworkAddresses = (
	mitigations: Pick<NetworkAddressDetail, 'mitigationPending' | 'networkAddress'>[]
): string[] => {
	return [...mitigations]
		.sort((a, b) => {
			const aNumber = a.mitigationPending ? 1 : 0;
			const bNumber = b.mitigationPending ? 1 : 0;
			return bNumber - aNumber;
		})
		.map((i) => i.networkAddress);
};

export const calculateOneMinAvg = (duration: number, type: string, unit: string, samples: number[]) => {
	if (duration === 60) {
		return samples[samples.length - 1];
	}
	return samples[samples.length - 1];
};

export const calculateFiveMinAvg = (duration: number, type: string, unit: string, samples: number[]) => {
	if (duration === 60) {
		const fiveMinSamples = samples.slice(Math.max(samples.length - 5, 0));
		return fiveMinSamples.reduce((sum, value) => sum + value) / fiveMinSamples.length;
	}
	return samples[samples.length - 1];
};

export const calculateSummaryAvg = (type: string, unit: string, ratesTotal: TrafficClass[]) => {
	return ratesTotal.find((r) => r.type === type && r.unit === unit)?.average;
};

export const calculatePercentDropped = (unit: string, passAvg: number, dropAvg: number) => {
	const total = passAvg + dropAvg;
	const percentDropped = total === 0 ? 0 : (dropAvg / total) * 100;
	return `${percentDropped.toFixed(2)}%`;
};

export const calculateTotalAvg = (unit: string, passAvg: number, dropAvg: number) => {
	return passAvg + dropAvg;
};

export const formatBytes = (value: number, unit: string) => {
	const units =
		unit === 'bps' ? ['Bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps'] : ['pps', 'Kpps', 'Mpps', 'Gpps', 'Tpps', 'Ppps'];

	let formattedValue = value;
	let i = 0;

	while (formattedValue >= 1024 && i < units.length - 1) {
		formattedValue /= 1000;
		i += 1;
	}

	return `${formattedValue.toFixed(2)} ${units[i]}`;
};

export const dropUnitKey = (unit: string) => `drop-${unit}`;
export const passUnitKey = (unit: string) => `pass-${unit}`;
export const calculateAverages = (
	duration: number,
	units: string,
	ratesTotal: TrafficClass[],
	trafficUtilSummData: TrafficUtilSummFlat
) => {
	const typeValues: DropAndPass = groupSamplesByTypeAndUnit(ratesTotal);

	const unit: string = units === 'bps' || units === 'pps' ? units : 'bps';

	const oneMinDropAvg: number = calculateOneMinAvg(duration, 'drop', unit, typeValues.drop[dropUnitKey(unit)]);
	const oneMinPassAvg: number = calculateOneMinAvg(duration, 'pass', unit, typeValues.pass[`pass-${unit}`]);
	const oneMinuteTotalAvg = formatBytes(calculateTotalAvg(unit, oneMinPassAvg, oneMinDropAvg), unit);
	const oneMinPercentDropped: string = calculatePercentDropped(unit, oneMinPassAvg, oneMinDropAvg);
	const fiveMinDropAvg: number = calculateFiveMinAvg(duration, 'drop', unit, typeValues.drop[dropUnitKey(unit)]);
	const fiveMinPassAvg: number = calculateFiveMinAvg(duration, 'pass', unit, typeValues.pass[`pass-${unit}`]);
	const fiveMinuteTotalAvg = formatBytes(calculateTotalAvg(unit, fiveMinPassAvg, fiveMinDropAvg), unit);
	const fiveMinPercentDropped: string = calculatePercentDropped(unit, fiveMinPassAvg, fiveMinDropAvg);
	const summaryDropAvg: number = calculateSummaryAvg('drop', unit, ratesTotal);
	const summaryPassAvg: number = calculateSummaryAvg('pass', unit, ratesTotal);
	const percentDropped: string = calculatePercentDropped(unit, summaryPassAvg, summaryDropAvg);
	const totalAvg = formatBytes(
		calculateTotalAvg(
			unit,
			ratesTotal.find((rate) => rate.unit === unit && rate.type === 'pass').average,
			ratesTotal.find((rate) => rate.unit === unit && rate.type === 'drop').average
		),
		unit
	);

	return [
		{
			type: 'drop',
			oneMinAvg: formatBytes(oneMinDropAvg, unit),
			fiveMinAvg: formatBytes(fiveMinDropAvg, unit),
			summaryAvg: formatBytes(summaryDropAvg, unit),
			totalUtilization: trafficUtilSummData.trafficDropped,
		},
		{
			type: 'pass',
			oneMinAvg: formatBytes(oneMinPassAvg, unit),
			fiveMinAvg: formatBytes(fiveMinPassAvg, unit),
			summaryAvg: formatBytes(summaryPassAvg, unit),
			totalUtilization: trafficUtilSummData.trafficPassed,
		},
		{
			type: 'total',
			oneMinAvg: oneMinuteTotalAvg,
			fiveMinAvg: fiveMinuteTotalAvg,
			summaryAvg: totalAvg,
			totalUtilization: trafficUtilSummData.totalTraffic,
		},
		{
			type: 'percent Dropped',
			oneMinAvg: oneMinPercentDropped,
			fiveMinAvg: fiveMinPercentDropped,
			summaryAvg: percentDropped,
			totalUtilization: trafficUtilSummData.percentageDropped,
		},
	];
};

export const doDateRangesOverlap = (dateRange1: string[], dateRange2: string[]) => {
	const start1 = Date.parse(dateRange1[0]);
	const end1 = Date.parse(dateRange1[1]);
	const start2 = Date.parse(dateRange2[0]);
	const end2 = Date.parse(dateRange2[1]);

	return start1 <= end2 && end1 >= start2;
};

export const convertUtcToEt = (utcDateString: string) => {
	const utcMoment = moment.utc(utcDateString, 'MM/DD/YYYY HH:mm:ss');
	const etMoment = utcMoment.tz('America/New_York');
	return new Date(etMoment.format('MM/DD/YYYY HH:mm:ss'));
};

export const getUniqueItems = (sortedItems: Mitigation[], mitigationStartRange: string): Mitigation[] => {
	let previousEndDate = '';
	const uniqueItems: Mitigation[] = [];
	sortedItems.forEach((item) => {
		if (!uniqueItems.length) {
			// Item is starting after completion of other items in the uniqueItems list, hence add it straight away
			uniqueItems.push(item);
			previousEndDate = item.attackEndDate;
		} else {
			const newItem: Mitigation = { ...item };
			if (new Date(previousEndDate) > new Date(newItem.attackStartDate)) {
				newItem.attackStartDate = previousEndDate;
			}
			newItem.attackStartDate =
				new Date(newItem.attackStartDate) > new Date(mitigationStartRange)
					? newItem.attackStartDate
					: mitigationStartRange;
			// Add the new item to uniqueItems
			uniqueItems.push(newItem);
			if (new Date(newItem.attackEndDate) > new Date(previousEndDate)) {
				previousEndDate = item.attackEndDate;
			}
		}
	});

	return uniqueItems;
};

export const calculateTotalMitigationHours = (
	list: Mitigation[],
	mitigationStartRange: string,
	mitigationEndRange: string
): number => {
	let totalHours = 0;

	// Sort the list by start date ascending and end date descending
	const sortedItems = [...list].sort((a, b) => {
		return (
			new Date(a.attackStartDate).getTime() - new Date(b.attackStartDate).getTime() ||
			new Date(b.attackEndDate).getTime() - new Date(a.attackEndDate).getTime()
		);
	});

	// Get unique items to avoid double counting of overlapping items
	const uniqueItems: Mitigation[] = getUniqueItems(sortedItems, mitigationStartRange);

	// Calculate the total hours based on the uniqueItems array
	uniqueItems.forEach((item) => {
		try {
			const endOfDay: number = moment.utc(mitigationEndRange).add(1, 'day').valueOf();
			let endDate: number;

			// Check if the item has an attack end date and if it's after the end of the day
			if (!item.attackEndDate) {
				endDate = endOfDay;
			} else if (moment.utc(item.attackEndDate).isAfter(moment.utc(endOfDay).valueOf())) {
				// If the attack end date is after the end of the day, set it to the end of the day
				endDate = endOfDay;
			} else {
				// Otherwise, use the attack end date as is
				endDate = moment.utc(item.attackEndDate).valueOf();
			}

			let startDate: number = null;
			if (moment.utc(item.attackStartDate).isAfter(moment.utc(mitigationStartRange))) {
				startDate = moment.utc(item.attackStartDate).valueOf();
			} else {
				startDate = moment.utc(mitigationStartRange).valueOf();
			}

			// Calculate the time difference between the start and end dates in milliseconds
			const ts: number = endDate - startDate;

			// Convert the time difference to hours
			const hours = ts / (1000 * 60 * 60);
			if (hours > 0) {
				totalHours += hours;
			}
			// eslint-disable-next-line no-empty
		} catch (_) {}
	});

	// Truncate total hours to one decimal point and return the result
	return Math.floor(totalHours);
};

export const getMitigationSummary = (mitigationRates: MitigationRate, mitigation?: Mitigation): MitigationSummary => {
	const { startTime, endTime, mitigationID, alertID } = mitigationRates;
	const formatTimeStamp = (timestamp: string) => moment.utc(timestamp).format('MMM DD HH:mm');
	const status = endTime
		? `${formatTimeStamp(startTime)} - ${formatTimeStamp(endTime)}`
		: `${formatTimeStamp(startTime)}`;

	return {
		alertID,
		mitigationID,
		mode: endTime ? 'Inactive' : 'Active',
		status,
		protectionPrefixes: mitigation?.ipDiverisonData,
		managedObject: mitigation?.zoneName,
	};
};

export const formatMitigationDuration = (mitigationDuration: string): string => {
	let resultDuration = '';
	const durationArr: string[] = mitigationDuration?.split(':');
	if (durationArr?.length < 3) {
		resultDuration = mitigationDuration;
	} else {
		const duration: moment.Duration = moment.duration(durationArr[0], 'd');
		duration.add(durationArr[1], 'h');
		duration.add(durationArr[2], 'm');
		duration.add(durationArr[3], 's');
		resultDuration = duration.days() > 0 ? `${duration.days()}d, ` : '';
		resultDuration = `${resultDuration}${durationArr[1]}:${durationArr[2]}:${durationArr[3]}`;
	}
	return resultDuration;
};

export const getMitigationDataSource = (mitigation: Mitigation): MitigationDataSource => {
	return {
		name: mitigation.mitigationName,
		portPrefixes: mitigation.ipDiverisonData,
		duration: formatMitigationDuration(mitigation.duration),
		attackStartDate: mitigation.attackStartDate,
		attackEndDate: mitigation.attackEndDate,
		startTime: moment.utc(mitigation.attackStartDate).format(dateFormat(mitigation.attackStartDate)),
		endTime: mitigation.attackEndDate
			? moment.utc(mitigation.attackEndDate).format(dateFormat(mitigation.attackEndDate))
			: 'In Progress',
		user: mitigation.cspUserIdStart,
		type: mitigation.isMitigationPim ? `Auto ${mitigation.mitigationName}` : mitigation.mitigationName,
		highlightRow: !mitigation.attackEndDate && 'endTime',
		link: `/traffic/${AppLayoutRouting.MITIGATION_DETAILS}/${mitigation.mitigationName}`,
		queryParams: {
			startTime: mitigation.attackStartDate,
			endTime: mitigation.attackEndDate,
			name: mitigation.mitigationName,
		},
	};
};

// build the order map data from the response.
export const getNetworkConnectionKeyObjects = (
	response: ApiResponse<NetworkConnectionCollection>
): NetworkConnectionKeyObject[] => {
	return [...(response.dataObject.networkConnectionList ?? [])].map((zone) => {
		const { netAddressDetailList, ...remain } = zone;
		return {
			...remain,
			...toKeyObject(netAddressDetailList, 'networkAddress'),
		};
	});
};

const getCMOKeyObjects = (response: ApiResponse<NetworkConnectionCollection>, isPim: boolean): CMOKeyObject[] => {
	return [...(response.dataObject.cmoList ?? [])]
		.filter((cmo) => cmo.pimIndicator === isPim)
		.map((pim) => {
			return {
				cmoName: pim.cmoName,
				...toKeyObject(pim.cmoIpList, 'cmoIp'),
			};
		});
};

export const getNonPIMCOMKeyObjects = (response: ApiResponse<NetworkConnectionCollection>): CMOKeyObject[] => {
	return getCMOKeyObjects(response, false);
};

export const getPIMCOMKeyObjects = (response: ApiResponse<NetworkConnectionCollection>): CMOKeyObject[] => {
	return getCMOKeyObjects(response, true);
};

export const getMitigations = (response: ApiResponse<MitigationCollection>): Mitigation[] => {
	return [...(response.dataObject?.mitigationList ?? [])];
};

export const getMitigationKeyObject = (response: ApiResponse<MitigationCollection>): MitigationKeyObject => {
	const mitigationsWithId = response.dataObject?.mitigationList.map((mitigation, index) => ({
		...mitigation,
		id: `${index}`,
	}));
	return toKeyObject(mitigationsWithId, 'id');
};

export const getUpdateNetworkConnections = (zone: NetworkConnectionKeyObject): UpdateNetworkConnections => {
	const { objects, snapshotKeys } = zone;
	const updateReq: UpdateNetworkConnections = {
		netConnectionID: zone.netConnectionID,
		serviceType: zone.serviceType,
		defaultZoneName: zone.defaultZoneName,
		siteID: zone.siteID,
		siteNameSSPP: zone.siteNameSSPP,
		companyID: zone.companyID,
		companyName: zone.companyName,
		circuitTier: zone.circuitTier,
		netAddressDetailList: snapshotKeys
			.map((k) => objects[k])
			.map((n) => ({
				networkAddress: n.networkAddress,
				arecordAssignmentIP: n.arecordAssignmentIP,
				ipType: n.ipType,
				name: n.name,
				mitigationPending: n.mitigationPending,
				mitigationReady: n.mitigationReady,
			})),
	};

	return updateReq;
};

export const getMitigationRate = (response: ApiResponse<MitigationRate>): MitigationRate => {
	return assertValue(response.dataObject);
};
