/* Copyright 2023, AT&T Intellectual Property. All rights reserved. */

import { KeyObjects, MakeAllKeysWithType } from '@src/types/types.utils';
import { Params } from '@angular/router';
import { ApiResponse, NameValueOption, QueryNode, TableColumn, TimeRangeParam, UnitType } from '../common.model';

export const ALL_VALUE = 'all';
export const BPS_VALUE = 'bps';
export const BYTESPS_VALUE = 'Bps';
export const PPS_VALUE = 'pps';
export const BEFORE_VALUE = 'before';
export const AFTER_VALUE = 'after';

export enum AlertStatusEnum {
	ONGOING = 'Ongoing',
	RECENT = 'Recent',
	ALL = 'All',
}

export type AlertSearchQuery = {
	searchString: QueryNode;
	importanceHigh: QueryNode;
	importanceMedium: QueryNode;
	importanceLow: QueryNode;
	alertClass: QueryNode;
	alertType: QueryNode;
	classification: QueryNode;
	size: QueryNode;
	onGoing: QueryNode;
	recent: QueryNode;
	startBeforeOrAfter: QueryNode;
	endBeforeOrAfter: QueryNode;
	startDate: QueryNode;
	endDate: QueryNode;
	startTime: QueryNode;
	endTime: QueryNode;
	lowThreshold: QueryNode;
	highThreshold: QueryNode;
	thresholdUnit: QueryNode;
	lowSeverityPercent: QueryNode;
	highSeverityPercent: QueryNode;
};

export enum DDOS_TITLE_TYPE {
	ALERT = 'ALERT',
	MITIGATION = 'MITIGATION',
}

export type AlertStatusFilter = AlertStatusEnum.ALL | AlertStatusEnum.ONGOING | AlertStatusEnum.RECENT;

export const TA_DROPDOWN_ALERTS_DISPLAYS = [
	{ type: AlertStatusEnum.ALL, value: AlertStatusEnum.ALL },
	{ type: AlertStatusEnum.RECENT, value: AlertStatusEnum.RECENT },
	{ type: AlertStatusEnum.ONGOING, value: AlertStatusEnum.ONGOING },
];

export type PageParams = {
	page: number;
	size: number;
};

export const TA_DROPDOWN_ALERT_CLASSES = [
	{ id: 0, type: 'BGP', value: 'bgp' },
	{ id: 1, type: 'Cloud Signaling', value: 'cloud_signaling' },
	{ id: 2, type: 'Data', value: 'data' },
	{ id: 3, type: 'DoS', value: 'dos' },
	{ id: 4, type: 'Mobile Analytics', value: 'mobile_analytics' },
	{ id: 5, type: 'Smart', value: 'smart' },
	{ id: 6, type: 'System Error', value: 'system_error' },
	{ id: 7, type: 'System Event', value: 'system_event' },
	{ id: 8, type: 'TMS', value: 'tms' },
	{ id: 9, type: 'Traffic', value: 'traffic' },
	{ id: 10, type: 'Total', value: 'total' },
];

export const TA_ALERT_TABLE_COLUMNS: TableColumn[] = [
	{
		columnName: 'id',
		dataKey: 'alertId',
		sortArrowPosition: 'left',
		isSortable: false,
		isTextFilter: true,
		isLink: true,
	},
	{
		columnName: 'importance',
		columnNameIcon: 'info',
		columnNameIconColor: 'primary',
		dataKey: 'importance',
		dataKeyIcon: 'error',
		dataKeyIconColor: 'ddos-error-900',
		sortArrowPosition: 'left',
		isSortable: true,
	},
	{
		columnName: 'class',
		dataKey: 'class',
		sortArrowPosition: 'left',
		isSortable: true,
		isFilterable: true,
	},
	{
		columnName: 'alert',
		dataKey: 'alert',
		sortArrowPosition: 'left',
		isSortable: true,
		isInnerHtmlString: true,
	},
	{
		columnName: 'start time',
		dataKey: 'startTime',
		sortArrowPosition: 'left',
		isSortable: true,
	},
	{
		columnName: 'classification & notes',
		dataKey: 'classAndNotes',
		sortArrowPosition: 'left',
		isSortable: true,
	},
];

export const TA_ALERT_SUMMARY_TABLE_COLUMNS: TableColumn[] = [
	{
		columnName: 'Severity',
		dataKey: 'severity',
		dataKeyIcon: 'error',
		dataKeyIconColor: 'ddos-error-900',
	},
	{
		columnName: 'Low/High Severity % Thresholds',
		columnNameIcon: 'info',
		columnNameIconColor: 'primary',
		dataKey: 'maxSeverity',
	},
	{
		columnName: 'Top Misuse Type',
		dataKey: 'topMisuseType',
	},
	{
		columnName: 'Low/High Alert Traffic Thresholds',
		columnNameIcon: 'info',
		columnNameIconColor: 'primary',
		dataKey: 'maxImpact',
	},
	{
		columnName: 'directions',
		dataKey: 'directions',
	},
	{
		columnName: 'Misuse Type',
		dataKey: 'misuseType',
	},
	{
		columnName: 'managed Object',
		dataKey: 'managedObject',
	},
	{
		columnName: 'target',
		dataKey: 'target',
	},
];

export type SelectedCustomerOption = NameValueOption & {
	facetId: string[];
};

export type Annotation = {
	text: string;
	added: string;
};

export type ChartDataset = {
	label: string;
	data: number[];
	fill?: boolean;
	borderColor?: string;
	pointRadius?: number;
	lineTension?: number;
	yAxisID?: string;
	backgroundColor?: string | any[];
};

export type AlertChartParams = {
	datasets: ChartDataset[];
	chartLabels: string[];
};

export type AlertChartDatParams = {
	datasets: ChartDataset[];
	chartLabels: string[];
};

export type AlertIdentifier = {
	alertId: number;
};

export type Alert = AlertIdentifier &
	TimeRangeParam & {
		alertClass: string;
		alertType: string;
		annotationList?: {
			text: string;
			added: string;
		}[];
		classification: string;
		importance: string;
		ongoing: boolean;
		direction: string;
		targetIPAddress: string;
		impactBps: number;
		impactPps: number;
		misuseTypes: string[];
		severityPercent: number;
		severityThreshold: number;
		severityUnit: string;
		fastDetected: boolean;
		duration?: string;
		moName: string;
		region?: string; // Client Prop
		site?: string; // Client Prop
	};

export type AlertCollection = {
	defaultZoneName: string;
	alertData: Alert[];
};

export type GetAlertsResponse = ApiResponse<AlertCollection>;

export type CustomerAlertDetailPayload = AlertIdentifier & {
	unit?: UnitType;
};

export type Item = {
	itemName: string;
	pct95: number;
	average: number;
	max: number;
	timeseries: number[];
};

export type DataSet = Partial<TimeRangeParam> & {
	name: string;
	items: Item[];
};

export type AlertDetail = AlertIdentifier &
	TimeRangeParam & {
		unit: string;
		duration: number;
		dataSets: DataSet[];
	};

export type AlertDetailCollection = {
	defaultZoneName: string;
	alertData: AlertDetail[];
};

export type GetAlertDetailsResponse = ApiResponse<AlertDetailCollection>;

export type AlertDataSource = AlertIdentifier &
	TimeRangeParam & {
		importance: string;
		class: string;
		alert: string;
		classAndNotes: string;
		link: string;
	};

export type AlertKeyInformation = {
	maxSeverity: string;
	topMisuseType: string;
	maxImpact: string;
	directions: string;
	misuseType: string;
	managedObject: string;
	target: number | string;
};

export type AlertCharacterization = {
	dataSetName: string;
	dataSetItem: string;
	datasetValue?: string | number;
	dataSetItemPercent: string;
};

export type AlertTrafficDataset = {
	dataSetName: string;
	dataSetItems: AlertCharacterization[];
};

export type AlertDetailTitle = {
	title: string;
	duration: string;
};

export type AlertTitleParams = {
	alertType: string;
	direction: string;
	targetIPAddress: string;
	alertOwnedBy: string;
	misuseTypes: string[];
};

export type AlertDetailQueryParam = MakeAllKeysWithType<AlertIdentifier, string> &
	TimeRangeParam & {
		unit?: UnitType;
	};

export type AlertDetailTitleParams = Partial<TimeRangeParam> & {
	defaultZoneName: string;
};

export type AlertKeyObject = Omit<AlertCollection, 'alertData'> & KeyObjects<Alert>;
export type AlertDetailKeyObject = Omit<AlertDetailCollection, 'alertData'> & KeyObjects<AlertDetail[]>;
